var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-tally"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("How many times endpoints have been called in the past month.")];
      },
      proxy: true
    }])
  }, [[_vm._v("API Usage")]], 2), _c('div', {
    staticClass: "block block-rounded block-fx-pop"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_vm.loading.getGridfetchUsage ? _c('Spinner') : _c('apexchart', {
    attrs: {
      "height": "350",
      "options": _vm.usageChartOptions,
      "series": _vm.usageChartSeries
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }